import React, { useEffect, useState } from "react";
import { ScrollMenu, VisibilityContext } from "react-horizontal-scrolling-menu";
import usePreventBodyScroll from "./usePreventBodyScroll";
import axios from "axios";
import Layout from "../../layout/Layout";
import "./home.css";

import DOWNLOAD_ICON from "./../../assets/cloud-download.svg";
import COUVERT from "./../../assets/couvert.svg";
import PEOPLE from "./../../assets/people.svg";
import STARS from "./../../assets/stars.svg";
import CART from "./../../assets/cart.svg";
import MockUp from "./../../assets/commande-vendeuse.png";
import Tacos from "./../../assets/taco.png";
import Ios from "./../../assets/app-store-logo.png";
import Android from "./../../assets/google-play-logo.png";
import StarOff from "./../../assets/fade-star.png";
import StarOn from "./../../assets/active-star.png";
import PaymentInformation from "./../../assets/payment-information-bro.png";
import ServiceBro from "./../../assets/service-bro.png";
import MobileUser from "./../../assets/mobile-user-bro.png";
import POLYGON from "./../../assets/polygon-image.png";

function onWheel(apiObj, ev) {
  const isThouchpad = Math.abs(ev.deltaX) !== 0 || Math.abs(ev.deltaY) < 15;

  if (isThouchpad) {
    ev.stopPropagation();
    return;
  }

  if (ev.deltaY < 0) {
    apiObj.scrollNext();
  } else if (ev.deltaY > 0) {
    apiObj.scrollPrev();
  }
}

const Home = () => {
  const [device, setDevice] = useState("");
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState("");
  const [error, setError] = useState("");
  const [nom, setNom] = useState("");
  const [prenoms, setPrenoms] = useState("");
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");

  const child = { width: `30em`, height: `90%` };

  const { disableScroll, enableScroll } = usePreventBodyScroll();

  const validateEmail = () => {
    let res = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
    return res.test(email);
  };

  const sendMessage = async () => {
    setError("");
    setSuccess("");
    if (nom && prenoms && email && message) {
      if (validateEmail(email)) {
        setLoading(true);
        const messageData = {
          nom,
          prenoms,
          email,
          message,
        };

        try {
          await axios
            .post(
              `http://5.196.4.7:8080/restaurantapplication-api/site/contacteznous`,
              messageData
            )
            .then((res) => {
              setNom("");
              setPrenoms("");
              setEmail("");
              setMessage("");
              setSuccess("Message envoyé avec succès");
              setError("");
              setLoading(false);
            })
            .catch((error) => {
              console.log("Something went wrong", error);
              setError("Une erreur est survenu");
              setSuccess("");
              setLoading(false);
            });
        } catch (error) {
          setLoading(false);
          setError("Une erreur est survenu");
          setSuccess("");
          console.log("ERROR", error);
        }
      } else {
        setError("Entrer une email correcte");
      }
    } else {
      setLoading(false);
      setError("Renseigner tout les champs");
      console.log("renseigner tout les champs");
    }
  };

  useEffect(() => {
    if (navigator.platform.indexOf("Win") !== -1) {
      setDevice("windows");
    } else if (navigator.platform.indexOf("Mac") !== -1) {
      setDevice("mac");
    } else if (navigator.platform.indexOf("X11") !== -1) {
      setDevice("x11");
    } else if (navigator.platform.indexOf("Linux") !== -1) {
      setDevice("linux");
    } else if (navigator.platform.indexOf("iPhone") !== -1) {
      setDevice("iphone");
    } else if (navigator.platform.indexOf("Android") !== -1) {
      setDevice("android");
    } else {
      setDevice("unknow");
    }
  }, []);

  return (
    <Layout>
      <div
        className="header-background -mt-10 w-full h-fit pt-44 pb-20"
        id="decouvre-nous"
      >
        <div className="flex flex-col gap-y-10 items-center justify-center w-full h-full">
          <h1 className="text-5xl md:text-6xl lg:text-7xl text-center text-white font-bold">
            Pass Dabali
          </h1>
          <p className="text-xl md:text-2xl lg:text-3xl gradiant-text text-center font-semibold px-4">
            L’allié bonheur de vos papilles au travail
          </p>

          {device === "windows" ||
          device === "android" ||
          device === "linux" ||
          device === "x11" ||
          device === "unknow" ? (
            <a
              href="https://play.google.com/store/apps/details?id=com.skan.skanresto"
              className="w-auto"
            >
              <button className="bg-white text-black font-bold w-72 sm:w-80 h-12 rounded-full flex items-center justify-center gap-x-2">
                <img src={DOWNLOAD_ICON} className="w-7 md:w-8" />
                <span className="font-medium font-poppins text-sm md:text-base">
                  Télécharger l'application
                </span>
              </button>
            </a>
          ) : device === "mac" || device === "iphone" ? (
            <a href="https://google.com" className="w-auto">
              <button className="bg-white text-black font-bold w-72 sm:w-80 h-12 rounded-full flex items-center justify-center gap-x-2">
                <img src={DOWNLOAD_ICON} className="w-7 md:w-8" />
                <span className="font-medium font-poppins text-sm md:text-base">
                  Télécharger l'application
                </span>
              </button>
            </a>
          ) : (
            ""
          )}

          <div className="w-[95vw] sm:w-11/12 md:w-10/12 xl:w-9/12 max-w-screen-xl mx-auto mt-20 flex flex-cl gap-y-8 gap-x-5 md:flex-row items-center justify-between">
            <div>
              <div className="flex items-center justify-end gap-x-3">
                <p className="font-medium font-poppins text-sm text-white md:w-64 xl:w-80 text-right">
                  Commandez vos repas en toute tranquilité
                </p>
                <img src={COUVERT} className="w-12 sm:w-12 md:w-16" />
              </div>
              <div className="flex items-center gap-x-3 mt-8">
                <p className="font-medium font-poppins text-sm text-white md:w-64 xl:w-80 text-right">
                  Solo ou entre collègues, profitez des repas que vous méritez
                </p>
                <img src={PEOPLE} className="w-12 sm:w-12 md:w-16" />
              </div>
            </div>
            <div>
              <div className="flex items-center gap-x-3">
                <img src={STARS} className="w-12 sm:w-12 md:w-16" />
                <p className="font-medium font-poppins text-sm text-white md:w-64 xl:w-80">
                  Profitez d’une expérience de pause repas unique
                </p>
              </div>
              <div className="flex items-center gap-x-3 mt-8">
                <img src={CART} className="w-12 sm:w-12 md:w-16" />
                <p className="font-medium font-poppins text-sm text-white md:w-64 xl:w-80">
                  Réglez facilement votre consommation
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="pt-16 pb-20">
        <h1 className="text-2xl md:text-3xl lg:text-4xl px-4 text-center font-bold gradiant-text">
          Pourquoi choisir Pass Dabali
        </h1>
        <div className="flex sm:-ml-16">
          <div className="w-full flex justify-center">
            <img src={POLYGON} className="w-full sm:w-[750px] mx-auto" />
          </div>
        </div>
      </div>

      <div className="illustration-bg w-full bg-black pt-16 pb-32">
        <h1 className="gradiant-text text-2xl md:text-3xl lg:text-4xl font-bold text-center px-4">
          Ce que nos clients disent de nous
        </h1>
        <div className="flex flex-col md:flex-row gap-x-4 gap-y-5 mt-20 w-10/12 sm:w-9/12 md:w-11/12 lg:w-9/12 max-w-screen-xl mx-auto">
          <div className="bg-white p-4 rounded-2xl rounded-tl-none">
            <p className="font-poppins">
              “Mes collègues et moi on valide le Pass Dabali. Grâce à cette
              appli, on mange ce qu’on aime tous les midis et sans tracas. C’est
              super pour travailler sereinement et être plus efficient.”
            </p>
          </div>
          <div className="bg-white p-4 rounded-2xl rounded-bl-none">
            <p className="font-poppins">
              “Le Pass Dabali a changé la vie de mes employés. Depuis que nous
              l’utilisons nos collaborateurs sont plus heureux et productifs.”
            </p>
          </div>
          <div className="bg-white p-4 rounded-2xl rounded-tr-none">
            <p className="font-poppins">
              “Avec le Pass Dabali, j’ai encore plus de clients et mon espace
              restaurant sur l’application m’aide à mieux m’organiser et suivre
              mon activité au quotidien.”
            </p>
          </div>
        </div>
      </div>

      <div className="w-11/12 sm:w-10/12 max-w-screen-xl mx-auto sm:pb-20 md:pb-32 relative z-50">
        <div className="illustration-section relative sm:h-80 mt-20 sm:mt-36 md:mt-56 flex flex items-center justify-between">
          <img
            src={MockUp}
            alt="phone mockup"
            className="mockup w-48 sm:w-64 md:w-auto"
          />
          <img
            src={Tacos}
            alt="tacos illustration"
            className="absolute right-10 -top-36 lg:-top-24 hidden md:block"
          />
          <p className="text-4xl font-serif text-white -mt-10 lg:mt-5 mr-5 hidden md:block">
            Télécharger notre <br /> application mobile
          </p>
          <p className="mobile-text text-3xl font-serif text-white -mt-10 lg:mt-5 mr-5 block md:hidden">
            Télécharger notre application mobile
          </p>
          <div className="download-store absolute -right-10 md:right-0 bottom-2 lg:bottom-0 lg:relative flex gap-x-5 lg:self-end mr-14">
            <div className="download-store-android bg-white rounded-full lg:rounded-tr-full lg:rounded-br-none lg:rounded-tl-full lg:rounded-bl-none px-4 sm:px-0 sm:w-32 lg:w-36 h-20 sm:h-24 lg:h-40 flex flex-col justify-center items-center">
              <div className="flex flex-col items-center">
                <img
                  src={Android}
                  alt="play store logo"
                  className="w-9 sm:w-12 lg:w-auto"
                />
                <div className="flex gap-x-1">
                  <img
                    src={StarOn}
                    alt="étoile active"
                    className="w-2 sm:w-auto"
                  />
                  <img
                    src={StarOn}
                    alt="étoile active"
                    className="w-2 sm:w-auto"
                  />
                  <img
                    src={StarOn}
                    alt="étoile active"
                    className="w-2 sm:w-auto"
                  />
                  <img
                    src={StarOn}
                    alt="étoile active"
                    className="w-2 sm:w-auto"
                  />
                  <img
                    src={StarOff}
                    alt="étoile inactive"
                    className="w-2 sm:w-auto"
                  />
                </div>
              </div>
              <p className="font-poppins font-bold text-xs sm:text-md text-center mt-2 lg:mt-5">
                4,0/5
              </p>
            </div>
            <div className="download-store-apple bg-white rounded-full lg:rounded-tr-full lg:rounded-br-none lg:rounded-tl-full lg:rounded-bl-none px-4 sm:px-0 sm:w-32 lg:w-36 h-20 sm:h-24 lg:h-40 flex flex-col justify-center items-center pt-5">
              <img
                src={Ios}
                alt="logo apple store"
                className="w-9 sm:w-12 lg:w-auto"
              />
              <div className="flex gap-x-1 mt-2 lg:mt-5">
                <img
                  src={StarOn}
                  alt="étoile active"
                  className="w-2 sm:w-auto"
                />
                <img
                  src={StarOn}
                  alt="étoile active"
                  className="w-2 sm:w-auto"
                />
                <img
                  src={StarOn}
                  alt="étoile active"
                  className="w-2 sm:w-auto"
                />
                <img
                  src={StarOn}
                  alt="étoile active"
                  className="w-2 sm:w-auto"
                />
                <img
                  src={StarOn}
                  alt="étoile active"
                  className="w-2 sm:w-auto"
                />
              </div>
              <p className="font-poppins font-bold text-xs sm:text-md text-center mt-2 lg:mt-4">
                5/5
              </p>
            </div>
          </div>
        </div>
      </div>

      <div className="table-bg w-full pt-10" id="how-it-work">
        <h1 className="gradiant-text text-2xl md:text-3xl lg:text-4xl font-extrabold text-center px-4 mt-16 pb-14">
          Comment ça marche ?
        </h1>

        <div className="w-full mt-10">
          <div onMouseEnter={disableScroll} onMouseLeave={enableScroll}>
            <ScrollMenu onWheel={onWheel}>
              <div className="w-64 h-72 sm:w-72 sm:h-80 lg:w-80 lg:h-96 mx-4 flex items-center justify-center">
                <img src={MobileUser} className="w-full" />
              </div>
              <div className="w-64 h-72 sm:w-72 sm:h-80 lg:w-80 lg:h-96 p-6 mx-4 flex flex-col justify-between bg-[#FFDDDD]">
                <h1 className="font-poppins text-base md:text-lg">
                  Salarié ou indépendant
                </h1>
                <p className="font-poppins text-base md:text-lg">
                  Téléchargez l’appli Pass Dabali, créez votre compte,
                  couplez-le au Mobile Money de votre choix et rechargez-le.
                </p>
              </div>
              <div className="w-64 h-72 sm:w-72 sm:h-80 lg:w-80 lg:h-96 p-6 mx-4 flex flex-col justify-between bg-[#FFDDDD]">
                <h1 className="font-poppins text-base md:text-lg">
                  Salarié ou indépendant
                </h1>
                <p className="font-poppins text-base md:text-lg">
                  Rendez-vous dans votre resto préféré ou commandez depuis
                  l’appli ce que vous souhaitez.
                </p>
              </div>
              <div className="w-64 h-72 sm:w-72 sm:h-80 lg:w-80 lg:h-96 p-6 mx-4 flex flex-col justify-between bg-[#FFDDDD]">
                <h1 className="font-poppins text-base md:text-lg">
                  Salarié ou indépendant
                </h1>
                <p className="font-poppins text-base md:text-lg">
                  Régalez-vous et partagez vos belles expériences avec nous !
                </p>
              </div>
            </ScrollMenu>
          </div>
        </div>

        <div className="w-full mt-16 lg:mt-24 xl:mt-44">
          <div onMouseEnter={disableScroll} onMouseLeave={enableScroll}>
            <ScrollMenu onWheel={onWheel}>
              <div className="w-64 h-72 sm:w-72 sm:h-80 lg:w-80 lg:h-96 mx-4 flex items-center justify-center">
                <img src={ServiceBro} className="w-full" />
              </div>
              <div className="w-64 h-72 sm:w-72 sm:h-80 lg:w-80 lg:h-96 p-6 mx-4 flex flex-col justify-between bg-[#FFC8A6]">
                <h1 className="font-poppins text-base md:text-lg">
                  Entreprise
                </h1>
                <p className="font-poppins text-base md:text-lg">
                  Faites-vous recontacter rapidement par un de nos conseillers
                  Pass Dabali en remplissant le formulaire.
                </p>
              </div>
              <div className="w-64 h-72 sm:w-72 sm:h-80 lg:w-80 lg:h-96 p-6 mx-4 flex flex-col justify-between bg-[#FFC8A6]">
                <h1 className="font-poppins text-base md:text-lg">
                  Entreprise
                </h1>
                <p className="font-poppins text-base md:text-lg">
                  Échangez sur les besoins de votre structure et validez la mise
                  en place de votre compte entreprise.
                </p>
              </div>
              <div className="w-64 h-72 sm:w-72 sm:h-80 lg:w-80 lg:h-96 p-6 mx-4 flex flex-col justify-between bg-[#FFC8A6]">
                <h1 className="font-poppins text-base md:text-lg">
                  Entreprise
                </h1>
                <p className="font-poppins text-base md:text-lg">
                  Profitez de votre espace digital Pass Dabali pour faire vivre
                  une expérience de repas inédite à vos collaborateurs.
                </p>
              </div>
            </ScrollMenu>
          </div>
        </div>

        <div className="w-full mt-16 lg:mt-24 xl:mt-44 pb-40">
          <div onMouseEnter={disableScroll} onMouseLeave={enableScroll}>
            <ScrollMenu onWheel={onWheel}>
              <div className="w-64 h-72 sm:w-72 sm:h-80 lg:w-80 lg:h-96 mx-4 flex items-center justify-center">
                <img src={PaymentInformation} className="w-full" />
              </div>
              <div className="w-64 h-72 sm:w-72 sm:h-80 lg:w-80 lg:h-96 p-6 mx-4 flex flex-col justify-between bg-[#EBFCFD]">
                <h1 className="font-poppins text-base md:text-lg">
                  Restaurant
                </h1>
                <p className="font-poppins text-base md:text-lg">
                  Téléchargez l’appli Pass Dabali, créez votre compte,
                  renseignez-le et pilotez votre activité
                </p>
              </div>
              <div className="w-64 h-72 sm:w-72 sm:h-80 lg:w-80 lg:h-96 p-6 mx-4 flex flex-col justify-between bg-[#EBFCFD]">
                <h1 className="font-poppins text-base md:text-lg">
                  Restaurant
                </h1>
                <p className="font-poppins text-base md:text-lg">
                  Recevez des commandes et encaissez vos clients en un clic dans
                  l’appli.
                </p>
              </div>
              <div className="w-64 h-72 sm:w-72 sm:h-80 lg:w-80 lg:h-96 p-6 mx-4 flex flex-col justify-between bg-[#EBFCFD]">
                <h1 className="font-poppins text-base md:text-lg">
                  Restaurant
                </h1>
                <p className="font-poppins text-base md:text-lg">
                  Faites-vous rembourser rapidement par Mobile Money.
                </p>
              </div>
            </ScrollMenu>
          </div>
        </div>
      </div>

      {/* Début contact section */}
      <div className="w-11/12 sm:w-10/12 max-w-screen-xl mx-auto">
        <div
          id="contact"
          className="pt-28 sm:pt-36 mb-20 lg:px-10 flex flex-col lg:flex-row items-center justify-between gap-x-6"
        >
          <div className="w-full px-5 sm:px-10 lg:px-0 lg:w-2/5">
            <div>
              <h5 className="font-extrabold text-3xl sm:text-5xl">
                Nos contacts
              </h5>
              <p className="font-poppins text-sm sm:text-md mt-5">
                Nous sommes disponibles, n’hésitez pas à nous contacter. Nous
                vous répondons dans les 24h
              </p>
            </div>
            <div className="mt-5 sm:mt-10">
              <h5 className="font-extrabold text-2xl sm:text-3xl">Adresse</h5>
              <p className="font-poppins text-sm sm:text-md mt-5">
                2 Plateaux,
              </p>
              <p className="font-poppins text-sm sm:text-md">
                Abidjan, Côte d’Ivoire
              </p>
            </div>
            {/* <div className="mt-5 sm:mt-10">
              <h5 className="font-serif font-medium text-2xl sm:text-3xl">
                Réseaux sociaux
              </h5>
              <div className="flex items-center gap-x-3 mt-5">
                <img src={Facebook} alt="logo facebook" />
                <img src={Twitter} alt="logo twitter" />
                <img src={Linkedin} alt="logo linkedin" />
              </div>
            </div> */}
          </div>
          <div className="mt-16 sm:mt-24 lg:mt-0">
            <div className="form-container bg-white w-full h-auto px-4 sm:px-10 py-10 rounded-2xl">
              {success ? (
                <p className="text-center text-sm text-green-600 font-medium bg-green-100 px-2 py-2 inline-block rounded-md mb-10">
                  {success}
                </p>
              ) : null}
              {error ? (
                <p className="text-center text-sm text-red-600 font-medium bg-red-100 px-2 py-2 inline-block rounded-md mb-10">
                  {error}
                </p>
              ) : null}
              <div className="flex gap-x-4">
                <div>
                  <p className="text-sm sm:text-md">Nom*</p>
                  <input
                    type="text"
                    id="lname"
                    name="lname"
                    className="px-3 bg-gray-100 w-full h-11 text-sm sm:text-base sm:h-12 rounded-2xl sm:rounded-full"
                    value={nom}
                    onChange={(e) => setNom(e.target.value)}
                  />
                </div>
                <div>
                  <p className="text-sm sm:text-md">Prénoms*</p>
                  <input
                    type="text"
                    id="lname"
                    name="lname"
                    className="px-3 bg-gray-100 w-full h-11 text-sm sm:text-base sm:h-12 rounded-2xl sm:rounded-full"
                    value={prenoms}
                    onChange={(e) => setPrenoms(e.target.value)}
                  />
                </div>
              </div>
              <div className="mt-5">
                <p className="text-sm sm:text-md">Email*</p>
                <input
                  type="email"
                  id="lname"
                  name="lname"
                  className="px-3 bg-gray-100 w-full h-11 text-sm sm:text-base sm:h-12 rounded-2xl sm:rounded-full"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                />
              </div>
              <div className="mt-5">
                <p className="text-sm sm:text-md">Message</p>
                <textarea
                  id="lname"
                  name="lname"
                  className="px-3 py-2 bg-gray-100 w-full h-40 text-sm sm:text-base rounded-2xl"
                  value={message}
                  onChange={(e) => setMessage(e.target.value)}
                />
              </div>
              <div className="mt-5">
                <button
                  className="text-white text-sm sm:text-md bg-main rounded-full px-4 h-11"
                  onClick={sendMessage}
                  disabled={loading}
                >
                  {!loading ? "Envoyer mon message" : "Envoie en cours..."}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* Fin contact section */}
    </Layout>
  );
};

export default Home;
