import React, { useState } from "react";
import { Link } from "react-router-dom";
import Logo from "./../assets/pass_dabali.png";

const Navbar = () => {
  const [showMenuMobile, setShowMenuMobile] = useState(false);

  const handleBurgerMenu = (e) => {
    if (e.target.checked) {
      setShowMenuMobile(true);
    } else {
      setShowMenuMobile(false);
    }
  };

  const closeMenu = () => {
    console.log("oezb");
    setShowMenuMobile(false);
    document.getElementById("checkbox").checked = false;
  };

  return (
    <div className="relative w-full flex justify-center z-50 navbar">
      <div className="fixed z-50 bg-[#232229] h-20 w-full sm:w-11/12 md:w-10/12 max-w-screen-xl mx-auto rounded-bl-3xl rounded-br-3xl px-6 flex items-center justify-between">
        <Link to="/">
          <img
            src={Logo}
            alt="logo pass dabali"
            className="w-28 sm:w-36 -mt-3 md:-mt-2"
          />
        </Link>
        {/* <Link to="/">
          <h1 className="font-bold text-xl sm:text-2xl text-main">
            Pass Dabali
          </h1>
        </Link> */}
        <div className="flex items-center md:gap-x-10 lg:gap-x-16 hidden md:flex">
          <a href="/#decouvre-nous">
            <button className="text-white hover:text-main font-poppins font-normal hover:font-medium text-sm">
              Découvrez-nous
            </button>
          </a>
          <a href="/#how-it-work">
            <button className="text-white hover:text-main font-poppins font-normal hover:font-medium  text-sm">
              Comment ça marche?
            </button>
          </a>
          {/* <Link to="/">
            <button className="text-white hover:text-main font-poppins font-normal hover:font-medium  text-sm">
              Centre d'aide
            </button>
          </Link> */}
        </div>
        {/* <div className="hidden md:block">
          <button className="text-white text-sm bg-main h-10 px-5 rounded-full">
            Créer un compte
          </button>
        </div> */}
        <input
          id="checkbox"
          className="checkbox block md:hidden"
          type="checkbox"
          onChange={(e) => handleBurgerMenu(e)}
        />
        <div className="hamburger-lines block md:hidden cursor-pointer">
          <span className="line line1"></span>
          <span className="line line2"></span>
          <span className="line line3"></span>
        </div>
      </div>
      <div
        className="fixed -translate-y-52 w-full sm:w-11/12 md:w-10/12 mx-auto bg-[#232229] h-44 mt-12 rounded-bl-3xl rounded-br-3xl -z-10 ease-in-out"
        style={
          showMenuMobile ? { transform: "translateY(10px)", zIndex: 39 } : {}
        }
      >
        <div className="mt-10 flex flex-col items-center gap-y-8">
          <a href="/#decouvre-nous" onClick={closeMenu}>
            <button className="text-white hover:text-main font-poppins font-normal hover:font-medium text-md">
              Découvrez-nous
            </button>
          </a>
          <a href="/#how-it-work" onClick={closeMenu}>
            <button className="text-white hover:text-main font-poppins font-normal hover:font-medium  text-md">
              Comment ça marche?
            </button>
          </a>
          {/* <Link to="/centre-aide" onClick={closeMenu}>
            <button className="text-white hover:text-main font-poppins font-normal hover:font-medium  text-md">
              Centre d'aide
            </button>
          </Link> */}
        </div>
      </div>
    </div>
  );
};

export default Navbar;
