import React from "react";
import { Route, Routes } from "react-router";

// Pages
import Accueil from './../views/accueil/Accueil'
import NotFound from './../views/404/NotFound'
import Home from "../views/home/Home";
import CentreAide from "../views/centre-aide/CentreAide";

const AppRoutes = () => {
    return (
      <Routes>
        <Route exact path="/" element={<Home />} />
        {/* <Route exact path="/" element={<Accueil />} /> */}
        <Route exact path="/home" element={<Home />} />
        <Route exact path="/centre-aide" element={<CentreAide />} />
        <Route path="*" element={<NotFound />} />
      </Routes>
    );
  };
  
  export default AppRoutes;