import React from "react";
import { NavLink } from "react-router-dom";
import Logo from "./../../assets/SkanResto_White.png";

const NotFound = () => {
  return (
    <div className="w-screen h-screen bg-[#F6D4C0] flex flex-col items-center justify-center">
      {/* <img
        src={Logo}
        alt="logo skan resto"
        className="w-44 justify-self-start absolute top-10"
      /> */}
      <h1 className="text-main text-3xl font-bold justify-self-start absolute top-10">Pass Dabali</h1>
      <div className="text-center">
        <h1 className="font-serif text-7xl sm:text-9xl text-black text-center">
          404
        </h1>
        <p className="text-black font-poppins text-center md:text-lg mt-5">
          Cette page n'existe pas
        </p>
        <NavLink to="/">
          <button className="bg-main text-xs sm:text-sm md:text-md text-center text-white font-medium px-6 py-2 rounded-full mt-10">
            Retour au site
          </button>
        </NavLink>
      </div>
    </div>
  );
};

export default NotFound;
