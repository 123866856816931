import { BrowserRouter } from 'react-router-dom';
import AppRoutes from './router/router';

const App = () => {
  return (
    <BrowserRouter basename='/'>
      <AppRoutes />
    </BrowserRouter>
  );
}

export default App;