import React from "react";
import Logo from "./../assets/pass_dabali.png";
import Facebook from "./../assets/facebook.svg";
import Twitter from "./../assets/twitter.svg";
import Linkedin from "./../assets/linkedin.svg";
import { Link } from "react-router-dom";

const Footer = () => {
  return (
    <div>
      <div className="footer relative bg-[#232229] h-20 w-full sm:w-10/12 max-w-screen-xl mx-auto rounded-tl-3xl rounded-tr-3xl px-6 flex items-center justify-center">
        {/* <Link to="/">
          <img src={Logo} alt="logo pass dabali" className="w-24 lg:w-20" />
        </Link> */}
        {/* <Link to="/">
          <h2 className="text-xl text-main font-bold">Pass Dabali</h2>
        </Link> */}
        <div className="">
          <p className="text-white text-xs text-right sm:text-sm lg:text-md">
            © 2022 <span className="text-main">SKAN TECH</span> | Tous droits
            réservés
          </p>
        </div>
        {/* <div>
          <div className="flex items-center justify-center gap-x-5">
            <img
              src={Facebook}
              alt="logo facebook"
              className="w-4 sm:w-6 lg:w-auto"
            />
            <img
              src={Twitter}
              alt="logo twitter"
              className="w-4 sm:w-6 lg:w-auto"
            />
            <img
              src={Linkedin}
              alt="logo linkedin"
              className="w-4 sm:w-6 lg:w-auto"
            />
          </div>
        </div> */}
      </div>
    </div>
  );
};

export default Footer;
