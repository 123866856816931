import React from "react";
import Footer from "../components/Footer";
import Navbar from "../components/Navbar";

const Layout = (props) => {
  return (
    <>
      <Navbar />
      <div className="w-full pt-10 bg-[#F4F4F4]">{props.children}</div>
      <Footer />
    </>
  );
};

export default Layout;
